<template>
  <PageHeader title="General" :items="items"></PageHeader>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card">
          <div class="card-body">
            <div class="row g-3">
              <div class="col-md-3">
                <div class="search-box">
                  <input v-model="search" type="text" class="form-control search"
                         placeholder="Search for terminals...">
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <div class="col-md-auto ms-auto">
                <div class="d-grid gap-2">
                  <b-button variant="soft-success" class="w-lg waves-effect waves-light"
                            @click="createTerminal()">Create
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-cols-xxl-5 row-cols-lg-3 row-cols-md-2 row-cols-1 gy-3 pb-3">
          <div class="col" v-for="terminal in terminalsComputed" :key="terminal.id">
            <div class="collapse show">
              <div class="card mb-1">
                <div class="card-body">

                  <a class="d-flex align-items-center" data-bs-toggle="collapse"
                     :href="'#terminal_' + terminal.id" role="button" aria-expanded="false"
                     :aria-controls="'terminal_' + terminal.id"
                  >

                    <div class="flex-shrink-0">
                      <b-button variant="light"
                                class="position-relative p-0 avatar-xs rounded-circle">
                        <span class="avatar-title bg-transparent text-reset">
                            {{ terminal.name[0] }}
                        </span>
                      </b-button>
                    </div>

                    <div class="flex-grow-1 ms-3 d-flex justify-content-between overflow-hidden gap-3">
                      <div class="w-100 overflow-hidden">
                        <h6 class="fs-14 mb-1">{{ terminal.name }}</h6>
                        <p class="text-muted mb-0">
                          {{ terminal.free_days }} free days
                        </p>
                      </div>
                      <div>
                        <div v-b-tooltip.hover
                             :title="`${terminal.name} ${terminal.active ? 'is' : 'is not'} visible in terminal widgets`"
                             class="form-check" :class="{
                        'form-check-success': terminal.active,
                        'form-check-danger': !terminal.active,
                        }">
                          <input class="form-check-input" type="checkbox" disabled checked>
                        </div>
                      </div>
                    </div>

                  </a>
                </div>
                <form @submit.prevent="updateTerminal(terminal.id, terminal.name, terminal.free_days, terminal.active)"
                      class="collapse border-top border-top-dashed"
                      :id="'terminal_' + terminal.id">
                  <div class="card-body d-flex flex-column gap-3">
                    <div>
                      <h6 class="fs-14 mb-1">
                        Name
                      </h6>
                      <input class="form-control" type="text" v-model="terminal.name" maxlength="80" required>
                    </div>
                    <div>
                      <h6 class="fs-14 mb-1">
                        Free days
                      </h6>
                      <input class="form-control mb-1" type="number" v-model="terminal.free_days" max="365" required>
                      <small class="text-muted">
                        A number of days that containers can stay in the terminal for free
                      </small>
                    </div>
                    <div class="form-check form-check-success">
                      <input v-model="terminal.active" class="form-check-input" type="checkbox"
                             :id="`terminalIsActive${terminal.id}`">
                      <label class="form-check-label" :for="`terminalIsActive${terminal.id}`">
                        Show in terminal widgets
                      </label>
                    </div>
                  </div>
                  <div class="card-footer hstack gap-2">
                    <button class="btn btn-soft-success btn-sm w-50 fs-6" type="submit">
                      <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                      Save
                    </button>
                    <button class="btn btn-soft-danger btn-sm w-50 fs-6"
                            @click="deleteTerminalConfirmation(terminal)" type="button">
                      <font-awesome-icon icon="fa-solid fa-trash"/>
                      Delete
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <h6>Showing {{ terminalsComputed.length }} out of {{ terminals.length }} terminals</h6>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import PageHeader from "../../../../components/page-header.vue";
import axios from "axios";

export default {
  name: "StationsList",
  data() {
    return {
      terminals: [],
      search: '',
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Terminals List",
          active: true,
        },
      ]
    }
  },
  methods: {
    async getTerminals() {
      try {
        let response = await axios.get(`/terminal/`);
        this.terminals = response.data.results;
      } catch (e) {
        console.log(e)
      }
    },
    async createTerminal() {

      const {value: {terminal_name, free_days}} = await Swal.fire({
        title: 'Create a Terminal',
        html:
            '<input id="create_terminal_name" class="form-control w-75 m-auto mt-2" placeholder="Terminal Name">' +
            '<input id="create_terminal_free_days" type="number" class="form-control w-75 m-auto mt-2" placeholder="Free days">',
        focusConfirm: true,
        confirmButtonText: 'Create',
        confirmButtonColor: '#0AB39C',
        preConfirm: () => {
          let name = document.getElementById('create_terminal_name').value;
          let free_days = document.getElementById('create_terminal_free_days').value;
          if (name.trim().length === 0 || free_days.trim().length === 0)
            return Swal.showValidationMessage(`Please fill all the fields`);
          else {
            if (this.terminals.filter(terminal => terminal.name === name).length !== 0)
              return Swal.showValidationMessage(`A terminal with this name already exists`);
            else {
              return {terminal_name: name, free_days};
            }
          }
        }
      });

      if (terminal_name && free_days) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        });
        try {
          await axios.post(`/terminal/create/`, {
            name: terminal_name,
            free_days: free_days,
            active: true
          });
          await this.getTerminals();
          await Toast.fire({
            icon: 'success',
            title: 'Terminal created successfully'
          });
        } catch (error) {
          let html_text = ''
          try {
            html_text = Object.entries(error.response.data.extra.fields) ? Object.entries(error.response.data.extra.fields).map(([key, value]) => {
              return `<span class="fw-medium">${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}: </span> ${value}`
            }).join('<br>') : ''
          } catch {
            html_text = 'Error while creating a terminal'
          }
          await Swal.fire({
            icon: 'error',
            title: 'Error',
            html: html_text
          })
        }
      }
    },
    async updateTerminal(id, name, free_days, active) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      try {
        if (name.trim().length === 0 || free_days.toString().trim().length === 0) {
          await Toast.fire({
            icon: 'error',
            title: 'Please fill all the fields'
          })
          return
        }
        if (free_days > 365) {
          await Toast.fire({
            icon: 'error',
            title: 'Free days cannot be more than 365'
          })
          return
        }
        await axios.put(`/terminal/${id}/update/`, {
          name: name,
          free_days: free_days,
          active: active
        });
        await this.getTerminals()
        await Toast.fire({
          icon: 'success',
          title: 'Terminal updated successfully'
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'Could not update terminal'
        })
      }
    },

    async deleteTerminalConfirmation(terminal) {
      await Swal.fire({
        position: "center",
        icon: "error",
        title: `You are about to delete ${(terminal.name).toString()}`,
        text: 'Deleting this terminal will remove all of its information from our database',
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please type ${(terminal.name).toString()} to confirm`,
        input: 'email',
        inputPlaceholder: `${(terminal.name).toString()}`,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === (terminal.name).toString()) {
              resolve(this.deleteTerminal(terminal.id))
            } else {
              resolve('Terminal name did not match :)')
            }
          })
        }
      });
    },
    async deleteTerminal(id) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      try {
        await axios.delete(`/terminal/${id}/delete/`)
        await this.getTerminals()
        await Toast.fire({
          icon: 'success',
          title: 'Terminal deleted successfully'
        })
      } catch (error) {
        try {
          await Swal.fire({
            icon: 'error',
            title: error.response.data.message || 'Error deleting terminal',
            text: error.response.data.extra.protected_objects.join(', ')
          })
        } catch {
          await Swal.fire({
            icon: 'error',
            title: 'Error deleting terminal',
            text: 'Please, inform developers about this issue'
          })
        }
      }
    },

    async showResponse(response, successMessage) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      await Toast.fire({
        icon: response.status === 201 ? 'success' : 'error',
        title: response.status ? successMessage : 'Something went wrong'
      })
    }
  },
  async mounted() {
    await this.getTerminals()
  },
  computed: {
    terminalsComputed() {
      if (this.search.trim().length !== 0) {
        return this.terminals.filter(terminal => terminal.name.toLowerCase().includes(this.search.toLowerCase()))
      } else {
        return this.terminals
      }
    }
  },
  components: {
    PageHeader
  }
}
</script>

<style scoped>
</style>